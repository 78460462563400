<template>
  <div style="width:100%">
    <el-form :inline="true" :model="form">
      <el-form-item label="状态：">
        <el-select v-model="form.search_status" placeholder="请选择状态" style="width: 206px">
          <el-option :label="e.name" :value="e.id" v-for="(e, i) in statusList" :key="i"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="证书类型：">
        <el-select v-model="form.search_certificat_type" placeholder="请选择证书类型" style="width: 206px">
          <el-option :label="e.name" :value="e.id" v-for="(e, i) in categoryList" :key="i"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="btn_item">
        <el-button type="primary" @click="onSearch">查询</el-button>
        <el-button @click="onReset">重置</el-button>
      </el-form-item>
    </el-form>
    <!-- 列表 -->
    <div v-if="total > 0">
      <ul class="uu">
        <li v-for="(e, i) in tableList" :key="i">
          <img :src="e.resource_url" alt="" />
          <div class="box">
            <div class="title fsbc">
              <p>{{ e.name }}</p>
              <div>
                <new-viewonline :viewSrc="e.resource_url" :teaching_studio_user_resource_id="e.teaching_studio_user_resource_id"
                  :resourceId="e.r_resource_id || e.resource_id" fileExt="png" :itemData="e" :fileTitle="e.name" :fileSize="e.size"
                  :fileTime="e.create_time" fileNav="个人中心 > 我的证书 > 查看">
                  <el-button type="text">查看</el-button>
                </new-viewonline>
                <el-button type="text" class="del" @click="onRemove(e.id)">删除</el-button>
              </div>
            </div>

            <div class="time">
              <span>提交时间：</span>
              <span>{{ e.create_time }}</span>
            </div>
            <div>
              <span>状态：</span>
              <!-- 1 待审核  2审核通过  3审核不通过 -->
              <span :style="{ color: e.status == 1 ? '#FF8201' : e.status == 2 ? '#3489FE' : '#FF0607', }">{{ e.status_name }}</span>
            </div>
            <div class="certificat">
              <span>证书类型：</span>
              <span style="margin-right: 33px">{{ e.certificat_type_name }}</span>
              <span>所属工作室：</span>
              <span>{{ e.teaching_studio_name }}</span>
            </div>
          </div>
        </li>
      </ul>

      <div v-show="total" class="pagina_div" style="height: 60px;">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="form.page"
          :page-sizes="[10,20,30,40,50]" :page-size="form.limit" layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>

    <CommonEmpty v-else />
  </div>
</template>

<script>
import {
  CertificateList,
  delCertificate,
  CertificateCategory,
} from "@/api/teacherStudio";
export default {
  data() {
    return {
      form: {
        user_id: localStorage.getItem("id"),
        page: 1,
        limit: 10,
        search_status: "",
        search_certificat_type: "",
      },
      statusList: [
        { name: "全部", id: 0 },
        { name: "审核中", id: 1 },
        { name: "展示中", id: 2 },
        { name: "不通过", id: 3 },
      ],
      total: 0,
      tableList: [],
      categoryList: [],
    };
  },
  methods: {
    // 删除
    onRemove(id) {
      this.myConfirm({
        content: `确定要删除当前数据吗？`,
        fn: this.onDelApi,
        id,
      });
    },
    // 删除接口
    onDelApi(id) {
      delCertificate({ id, user_id: localStorage.getItem("id") }).then(
        (res) => {
          this.$message({
            showClose: true,
            message: "删除成功",
            type: "success",
          });
          this.onGetList();
        }
      );
    },
    //荣誉证书详情
    // {
    //     path: '/CertificateDetail',
    //     name: 'CertificateDetail',
    //     component: CertificateDetail,
    // },
    // 查看
    onGoDetail(id, item) {
      localStorage.setItem("studioId", item.teaching_studio_id);
      this.$router.push({
        path: "/pscertificatedetail",
        query: {
          id,
          is_from: 2,
        },
      });
    },
    //查询
    onSearch() {
      this.onGetList();
    },
    //重置
    onReset() {
      this.form = this.$options.data().form;
      this.onGetList();
    },
    handleSizeChange(val) {
      this.$set(this.form, "page", 1);
      this.$set(this.form, "limit", val);
      this.onGetList();
    },
    handleCurrentChange(val) {
      this.$set(this.form, "page", val);
      this.onGetList();
    },
    onGetList() {
      CertificateList(this.form).then((res) => {
        this.tableList = res.data.data;
        this.total = res.data.total;
      });
    },
  },
  created() {
    CertificateCategory({ user_id: localStorage.getItem("id") }).then((res) => {
      this.categoryList = res.data;
    });
    this.onGetList();
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-form {
  .el-form-item {
    margin-right: 30px;
  }
  .el-input__inner {
    height: 34px;
  }
  .el-form-item__label {
    padding: 0;
  }
  .el-button {
    padding: 9px 19px;
  }
  .btn_item {
    margin-right: 0;
  }
}
/deep/ .del {
  &:hover {
    span {
      color: #ff0607;
    }
  }
}
.uu {
  width: 100%;
  li {
    display: flex;
    border: 1px solid #f0f0f0;
    border-radius: 6px;
    padding: 15px 56px 15px 15px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px;
    &:hover {
      .title {
        color: #3489fe;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
    > img {
      width: 176px;
      height: 128px;
      border: 1px solid #f0f0f0;
      border-radius: 6px;
      margin-right: 20px;
    }
    .box {
      width: 100%;
      font-size: 14px;
      color: #666;
      .title {
        > p {
          font-weight: 600;
          font-size: 16px;
        }
        /deep/.el-button {
          span {
            color: #3489fe;
          }
        }
        .del {
          margin-left: 40px;
        }
      }
      .time {
        padding: 0px 0 11px;
      }
      .status {
        color: #ff8201;
      }
      .certificat {
        padding-top: 11px;
      }
    }
  }
}
</style>